.business-card {
  background: url(../images/business_card_bg.jpg) repeat;
  font-family: 'Poppins', sans-serif;
  padding: 10px 0 71px;

  &__container {
    width: 375px;
    margin: 0 auto;
  }

  &-main {
    background-color: #fff;
    border: 2px solid #000B4F;

    &__head {
      background:  #000B4F url(../images/bc_building.png) no-repeat center top;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 10px;

      &__dp {
        border-radius: 50%;
        border: 2px solid #fff;
        width: 120px;
        height: 120px;
        overflow: hidden;
        position: relative;
        z-index: 4;
        margin-top: 15px;
      }
    }

    &__personal {
      background-color: #000B4F;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
      color: #fff;
      position: relative;

      &::after {
        content: "";
        width: 180px;
        height: 120px;
        background-color: #000b4f;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-100%);
      }

      &__name {
        font-weight: 700;
        font-size: 20px;
        display: block;
        text-align: center;
      }

      &__designation {
        display: block;
        text-align: center;
        font-size: 16px;
      }

      &__contact {
        display: flex;
        border-top: 1px solid #fff;
        margin-top: 10px;

        &__item {
          display: block;
          height: 40px;
          flex: 1 1 auto;
          border-left: 1px solid #fff;

          &:first-child {
            border: none;
          }
        }
      }
    }

    &__other-info {
      padding: 0 15px 15px;

      &__item {
        padding: 6px 0 6px 45px;
        margin-top: 15px;

        &--address {
          background: url(../images/address_bc.png) no-repeat left center;
        }

        &--email {
          background: url(../images/email_bc.png) no-repeat left center;
        }

        &--web {
          background: url(../images/web_bc.png) no-repeat left center;
        }

        &--mobile {
          background: url(../images/call_bc.png) no-repeat left center;
        }
      }
    }

    &__social-icons {
      display: flex;
      justify-content: flex-end;
      position: relative;
      margin-top: -40px;

      &::after {
        content: "";
        clip-path: polygon(23% 0, 100% 0%, 100% 100%, 0% 118%);
        width: 139px;
        height: 41px;
        display: block;
        background-color: #000B4F;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
      }

      &__container {
        background-color: #fff;
        clip-path: polygon(23% 0, 100% 0%, 100% 100%, 0% 120%);
        height: 40px;
        max-width: 138px;
        flex: 1 1 auto;
        z-index: 2;
        padding-left: 25px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        &__item {
          display: inline-block;
        }
      }
    }
  }

  &-block {
    margin-top: 15px;
    background-color: #fff;
    border: 2px solid #000B4F;
    padding-bottom: 15px;

    &__header {
      font-family: 'Poppins', sans-serif;
      position: relative;
      margin-bottom: 20px;

      &__main {
        background-color: #fff;
        clip-path: polygon(0 0, 79% 0%, 61% 100%, 0 100%);
        width: 250px;
        padding: 8px 0 8px 15px;
        position: relative;
        z-index: 2;

        h3 {
          margin-bottom: 0;
          color: #000B4F;
          font-size: 20px;
          font-weight: 500;
        }
      }

      &::before {
        content: "";
          display: block;
          height: 10px;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color:#000B4F;
      }

      &::after {
        content: "";
        clip-path: polygon(0 0, 79% 0%, 61% 100%, 0 100%);
        width: 253px;
        height: 48px;
        display: block;
        background-color: #000B4F;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
      }
    }
  }

  &-about {
    display: flex;
    flex-wrap: wrap;

    &__item {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;

      &__stat {
        width: 80px;
        height: 80px;
        background-color: #000B4F;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 26px;
        margin-bottom: 5px;
      }

      &:first-child, &:first-child + .business-card-about__item {
        margin-top: 0;
      }
    }
  }

  &-technologies {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-services {
    color: #fff;
    text-align: center;

    &__heading {
      font-size: 20px;
      font-weight: 500;
      color: #000B4F;

      span {
        color: #999999;
      }
    }

    &__main {
      display: flex;
      flex-wrap: wrap;
      width: 310px;
      margin: 0 auto;
      justify-content: space-around;

      &__item {
        width: 49%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 30px 5px;
        margin: 1px 0;

        &__img {
          margin-bottom: 10px;
        }

        &__text {
          font-size: 12px;
        }

        &:nth-child(1), &:nth-child(4) {
          background-color: #000B4F;
        }

        &:nth-child(2), &:nth-child(3) {
          background-color: #30B9F7;
        }
      }
    }
  }

  &-clients {
    background-color: #f6f5f5;
    border: 1px solid #000B4F;
    display: flex;
    flex-wrap: wrap;
    width: 345px;
    margin:  0 auto;

    &__item {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 10px;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 80%;
        border-bottom: 1px solid #000B4F;
        position: absolute;
        top: 0;
      }

      &::after {
        content: "";
        display: block;
        height: 80%;
        border-right: 1px solid #000B4F;
        position: absolute;
        right: 0;
      }

      &:nth-child(1)::before, &:nth-child(2)::before, &:nth-child(even)::after {
        display: none;
      }

      &__img {
        max-width: 80%;
      }
    }
  }

  &-more-link {
    text-align: right;
    padding: 0 15px;
    margin-top: 5px;
  }

  &-testimonials {
    padding: 0 15px;

    &__item {
      color: #fff;
      border-radius: 8px;
      padding: 10px;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
      margin-top: 15px;

      &:first-child {
        margin-top: 0;
        background-color: #FF5520;
      }

      &:nth-child(2) {
        background-color: #90CC4B;
      }

      &:nth-child(3) {
        background-color: #30B9F7;
      }

      &__header {
        display: flex;
        align-items: center;

        &__author-img {
          margin-right: 5px;
        }

        &__author {
          display: block;
        }

        &__company {
          font-size: 12px;
        }
      }

      &__text {
        font-family: roboto, sans-serif;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin: 8px 0;
      }

      &__more-link {
        text-align: right;

        a {
          color: #fff;
        }
      }
    }
  }

  &-enquiry {
    padding: 0 15px;

    iframe {
      height: 565px !important;
    }
  }

  &-footer {
    text-align: center;
    background-color: #000B4F;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    padding: 15px;
    margin-top: 15px;

    &__member {
      width: 100%;

      &__heading {
        color: #fff;
        margin-bottom: 0;
        text-align: left;
      }

      &__logos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        padding: 5px;

        &__item {
          max-width: 100px;

          &:last-child {
            max-width: 55px;
          }
        }
      }
    }

    .clutch-widget {
      margin: 20px 0 10px;
    }
  }

  &-nav {
    background-color: #fff;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    box-shadow: 0 -12px 12px rgba(0,0,0,0.3);
    z-index: 3;

    &__main {
      display: flex;

      &__item {
        flex: 1 1 auto;
        text-align: center;

        &__link {
          display: block;
          padding: 60px 0 10px;
          background-repeat: no-repeat;
          background-position: center;
          font-size: 0;
          line-height: 0;
          color: transparent;

          &:hover, &:focus {
            color: #000B4F;
          }
        }
      }
    }
  }
}

.mobile-icon-bc {
  background: url(../images/call.png) no-repeat center;
}

.whatsapp-icon-bc {
  background: url(../images/whatsapp.png) no-repeat center;
}

.linkedin-icon-bc {
  background: url(../images/linkedin_icon_bc.png) no-repeat center;
}

.map-icon-bc {
  background: url(../images/location.png) no-repeat center;
}

.email-icon-bc {
  background: url(../images/email.png) no-repeat center;
}

.home-icon-bc {
  background-image: url(../images/home_bc.png);
}

.about-icon-bc {
  background-image: url(../images/about_bc.png);
}

.services-icon-bc {
  background-image: url(../images/services_bc.png);
}

.clients-icon-bc {
  background-image: url(../images/clients_bc.png);
}

.testimonials-icon-bc {
  background-image: url(../images/testimonial_bc.png);
}

.enquiry-icon-bc {
  background-image: url(../images/enquiry_bc.png);
}

.technologies-icon-bc {
  background-image: url(../images/technologies_icon_bc.png);
}

@media (min-width: 768px) {
  .business-card-nav__main__item__link {
    font-size: 13px;
    line-height: 1.5;
    color: #000B4F;
    padding: 50px 0 10px;
    background-position: center 20px;
  }
}