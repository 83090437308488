/**
 * Basic styling
 */
* {
  box-sizing: border-box;
}
body {
    font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
    color: $text-color;
    background-color: $background-color;
}
/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure {
    margin: 0 0 $spacing-unit / 2;
}
/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}
/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: $base-font-weight;
}
/**
 * Links
 */
a, a:hover, a:active, a:focus {
    color: $link-color;
    text-decoration: none;
}

