// Our variables
$base-font-family: "Roboto","Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size: 14px;
$base-font-weight: 400;
$semi-bold-font-weight: 600;
$bold-font-weight: 700;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color: #555555;
$background-color: #ffffff;
$brand-color: #DE903B;
$link-color: #009cf6;

$grey-color: #666666;
$grey-color-light: lighten($grey-color, 20%);
$grey-color-dark: darken($grey-color, 20%);
