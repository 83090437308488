@media (min-width: 768px) {
  .site-header {
    position: relative;
  
    &::after {
      clip-path: polygon(50% 0%, 100% 0, 100% 65%, 21% 100%, 0 75%, 0 0);
    }

    &__main {

      &__menu-icon {
        display: none;
      }

      &__nav {
        display: flex;
        position: relative;
        z-index: 100000000;
        background:none;
        width: auto;
        padding: 0;
        top: 0;

        &__item {
          margin-left: 50px;
          padding: 0;

          &__link {
            cursor: pointer;

            &:hover {
              color: #f5b168;
              font-weight: 400;
            }

            &--event-link {
              padding-left: 12px;
              font-size: 13px;
              padding-right: 0;
            }
          }

          &--event {
            margin-left: 0;
          }

          &__sub-menu {
            background-color: rgba(0, 0, 0, 0.9);
            position: absolute;
            top: 100%;
            min-width: 200px;
          }
        }

        &--event-section {
          width: 580px;
          margin-left: 30px;
          justify-content: flex-end;
        }
      }
    }
  }

  .show {
    display: block !important;
  }  

  .site-banner {
    padding: 100px 0 150px;
    margin-bottom: 70px;

    &__content {
      width: 40%;
      font-size:14px;
    }

    &__special-heading {
      width: 40%;
    }
  
    &__img {
      position: absolute;
      top: 0px;
      right: 0;

      &__foreground {
        top: 135px;
        left: 188px;
      }

      &--home {
        width: 500px;
      }

      &--clients, &--sg {
        width: 400px;
      }

      &--strength {
        width: 450px;
      }

      &--dedicated-development, &--software-development {
        width: 440px;
        top: 110px;
      }

      &--ceo2 {
        top:-42px;
      }

      &--prats {
        top:-80px;
      }
    }

    &__auct-info-note {
      width: 40%;
      padding: 15px;
    }

    &.venue-banner {
      margin-bottom: 0;
    }
  }

  .contact-footer {
    flex-direction: row;

    &__item {
      width: 48%;

      &:first-child {
        margin-bottom: 0;
      }
    }
  }

  .india,
  .us {
    margin-bottom: 20px;
    border-right: 1px dashed #858484;
    padding: 20px 20px 0;
    height: 80%;
  }

  .us {
    border-right: none;
  }

  .india-flag,
 .us-flag {
   font-size: 14px;
 }

  .location-wrap {
    flex-direction: row;
  }

  .location-info {
    &__btn {
      width: 50%;
    }
  }

  .site-footer {

    &__promotion{
      &__information__member, &__reviews {
        &__content {
          font-size: 18px;
          flex-direction: row;
          align-items: center;

          &__item {
            margin: 0 12px 0 10px;
          }
        }
      }
    }

    &__information__member__content {
      align-items: flex-end;
      text-align: left;
    }

    &__reviews__content {
      align-items: center;
    }

    &__legal-info {
      &__duns, &__policy {
        display: inline-block;
      }

      &__duns {
        padding: 0 10px;
        margin: 0 10px;
        border-width: 0 1px;
        border-style: solid;
        border-color: #fff;
      }
    }
  }

  .spacer-item {
    display: block;
    visibility: hidden;
  }

  .two-column-layout {
    padding-top: 0;
  }

  .clients {
    padding-top: 0;
  }

  .more-clients {
    &__logos {
      flex-direction: row;
  
      &__item {
        width: 50%;
      }
    }
  }

  .two-column-layout {
    &__item {
      .container {
        align-items: center;
      }

      &__img {
        max-width: 60%;
      }
    }
  }

  .testimonials__content__video iframe {
    height: 375px;
    width: 100%;
  }

  .carousel-indicators {
    bottom: -10px;
  }

  @keyframes ruby {
    0% {
      opacity: 1;
      transform: perspective(250px) translateX(-35px) translateY(45px) translateZ(-500px);
    }
    50% {
      opacity: 1;
      transform: perspective(250px) translateX(0px) translateY(0) translateZ(0px);
    }
    100% {
      opacity: 1;
      transform: perspective(250px) translateX(-35px) translateY(45px) translateZ(-500px);
    }
  }

  .benefits_wrap {
    &__item {
      max-width: 45%;
      margin: 0 30px 20px 0;
      text-align: left;
    }
  }

  .ai-wrap {
    &__item {
      max-width: 45%;
      margin: 0 30px 20px 0;
    }
  }

  .tech-page-testimonials {
    width: 50%;
    padding: 2px 0 0 0;
  }

  .site-banner__img--tech {
    width: 390px;
    top: 80px;
  }

  .value-statements {
    &__text {
      font-size: 32px;
    }
  }

  .services_wrap {
    width: 90%;

    &__item__number--right {
      bottom: -10px;
      top: auto;
      left: auto;
    }
  }

  .mg-left {
    margin-left: 88px;
  }

  //Events

  /* event landing */
  .event-landing {
    &__cards {
      justify-content: space-between;

      &__item {
        width: 49%;
      }
    }
  }
  /* event landing */

  /*ROR-WORKSHOP*/
  .ror-workshop-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .ror-workshop-note {
    &__message {
      font-size: 16px;
      color: #fff;
      margin: 15px 0 0 15px;
    }
  }
  /*ROR-WORKSHOP*/

  .event-participants {
    &__participants {
      width: 618px;

      &--tpo-participants {
        width: 624px;
      }
    }
  }

  .photo-gallery-image-item {
    width: 25%;
  }

  //Start HR-Meet

  .event-details-hr-meet {
    &__schedule {
      &__lists {
        &__item {
          background-position: 10px 3px;

          &__icon {
            display: inline-block;
            width:10%;
          }
        }
      }
    }

    &__speech {

      &__item {
        flex-direction: row;

        &__content {
          margin-left:15px;

          &__name {
            font-size: 20px;
          }
        }

        &__img {
          max-width: 150px;
          max-height: 150px;
          flex: 0 0 auto;
          margin:0;
        }
      }
    }
  }

  .event-details-hr-meet {
    flex-direction: column;

    &__welcome-section {
      max-width: 100%;

      &__item {
        flex-direction: row;
      }
    }

    &__felicitation {
      width:100%;
      padding: 0;

      &__speech {
        &__item {
          flex-direction: row;
        }
      }

      &__guest-speech {
      max-width: 100%;

        &__item {
          flex-direction: row;
        }
      }
    }

    &__lists {
      margin-left: 15px;

      &__item {
        &__icon {
          display: inline-block;
        }
      }
    }
  }

  .panel-terms {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &__item {
      background: #ff5e00;
      color: #fff;
      border-radius: 15px;
      padding: 10px 10px;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      width: 434px;
      height: 50px;
      font-size: 22px;
      box-shadow: 4px 7px 5px -5px rgba(0,0,0,0.75);

      &:hover {
        color: #fff;
        background: #067090;
      }
    }
  }

  .hr-pain-points {
    &__contents-wraper {
    flex-direction: row;
    }
  }

  // End HR-Meet

  /* Start TPO Meet */

  .tpo-event-details {
    &__testimonials {
      &__item {
        margin: 0 15px;
      }
    }
  }

  /* End TPO Meet */

  .event-speakers {
    &__speakers {
      &--hr-event-speaker {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      &__item {
        flex-direction: row;
        text-align: left;

        &__img {
          margin: 0;
        }

        &__details {
          padding: 0 15px 0 0;
          margin: 0 0 0 15px;
        }

        &--tpo-speaker {
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
      }

      &--tpo-speaker {
        justify-content: center;
      }
    }
  }

  //Start CEO Meet

  .event-details {
    &__left {
      flex-direction: row;

      span {
        width: 50%;
      }
    }

    .upcoming-event {
      span {
        &:nth-child(1) {
          margin-left: 25%;
        }
      }
    }
  }

  //End CEO Meet

  //Business IT Meet
  .business-it-moment {
    flex-direction: row;

    &__main {
      &__item + .business-it-moment__main__item {
        display: inline-block;
        margin: 0 0 0 25px;
      }
    }

    .btn {
      display: inline-block;
      width: auto;
    }
  }

  .date-icon, .time-icon {
    padding: 15px 0 15px 60px;
    font-size: 16px;
  }

  .business-it-details {
    &__about {
      flex-direction: row;

      &__main {
        width: 60%;
      }

      &__key-points {
        width: 40%;
        margin-left: 25px;
      }
    }

    &__speakers {
      &__main {
        &__item {
          width: 49%;
        }
      }
    }
  }

  /*AUCTION-1*/
  .kalpavriksha1-intro {
    flex-direction: row;
    margin-bottom: 50px;
  }

  .statistics-content {
    &__advantages {
      &__content {
        flex-direction: row;

        &__item{
          width: 25%;
        }
      }
    }
  }

  .testimonial-content {
    &__wrap {
      flex-direction: row;

      &__item {
        width: 45%;
      }
    }
  }
  /*AUCTION-1*/

  /*CEO2-Meet*/

  .ceo-event-meet-details {
    padding-top: 0;

    &__conference {

      &__content {
        flex-direction: row;

        &__img {
          width: 35%;
        }

        &__wrap {
          padding: 10px 10px 10px 60px;

          &--no-padding {
            padding-left: 0;
          }
        }

      }
    }
  }

  .event-time-venue {
    &__wrap {
      top: -80px;
    }
  }

  .event-venue {
    &__hotel-wrap {
      flex-direction: row;
      overflow: hidden;

      &__img {
        width: 65%;
      }
    }
  }

  .ceo-meet2-discussion {
    &__content {
      &__item {
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-start;
        text-align: left;
  
        &__img {
          margin-right: 40px;
          margin-bottom: 0;
        }
      }
    }
  }
  /*CEO2-Meet*/

  /*CEO3-Meet*/
  .ceo-meet-discussion {
    &__content {
      &__item {
        justify-content: normal;
        flex-direction: row;
        align-items: flex-start;
        text-align: left;
  
        &__img {
          margin-right: 40px;
          margin-bottom: 0;
        }
      }
    }
  }
  /*CEO3-Meet*/

  //Events
  /* Case Studies */
  .case-study {
    &__section {
      border-left: 1px solid #999999;
      padding: 0 0 50px 50px;
      margin-bottom: 0;

      &--no-border {
        border-left: none;
      }

      &__challenge {
        &__item {
          border-left: 1px solid #999999;
          padding: 15px 0 50px 20px;

          &:last-child {
            padding-bottom: 0;
          }

          &--no-border {
            border-left: none;
          }
  
          &:first-child:before, &:last-child:before {
            content: "";
            width: 65px;
            height: 1px;
            background: #999;
            display: block;
            position: absolute;
            left: -50px;
            top: 0;
          }
  
          &__indicator {
            color: #999999;
            display: block;
            position: absolute;
            top: -12px;
          }
  
          &__main {
            flex-direction: row;

            img {
              max-width: 138px;
            }
  
            &__content {
              margin-left: 25px;
            }
          }
        }
      }

      &__solution {
        &--mobile {
          display: none;
        }

        &--desktop {
          display: block;
        }
        
        &__row {
          border: none;

          &__left, &__right {
            width: 49%;
            border: 2px dashed #ccc;
          }
        }
      }

      &__results {
        flex-direction: row;

        &__item {
          width: 48%;
        }
      }

      &__technologies {
        justify-content: flex-start;

        &__item {
          width: auto;
          margin-right: 15px;

          &__logo {
            width: 150px;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .site-banner {
    &__img {
      width: 416px;

      &--sg {
        width: 470px;
      }

      &--tech {
        width: 506px;
      }
    }
  }

  .site-header {
    &__main__nav {
      &__item {
        &__link {
          &--drop-link {
            margin: 0 28px;
          }

          &--event-link {
            padding-left: 18px;
            font-size: 14px;
          }
        }
      }

      &--event-section {
        width: auto;
        margin-left: 0;
      }
    }
  }

  .home-heading {
    font-size: 48px;
    margin-bottom: 75px;

    &--our-strength, &--our-works, &--dedicated-development, &--software-development {
      margin-bottom: 25px;
    }
  }

  .site-banner {
    &__heading {
      font-size: 54px;

      &--small {
        font-size: 50px;
      }
    }

    &__img {
      &--clients {
        width: 525px;
      }

      &--dedicated-development, &--software-development {
        width: 500px;
        top: 102px;
      }
    }
  }

  .our-stats {
    padding: 100px 0 70px;
  
    &__content {
      flex-direction: row;
      align-items: flex-end;
    }
  }

  .technologies-sec {
    padding: 100px 0 70px;
  }

  .our-strength {
    padding: 100px 0;
    clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0% 100%);

    &__content {
      flex-direction: column;
      text-align: center;
      align-items: center;

      &__img {
        width: 60%;
        margin-left: 25px;
        flex: 0 0 auto;
      }
    }
  }

  .our-clients {
    padding: 70px 0;
  
    &__heading {
      font-size: 48px;
    }
  }
  
  .client-logos {
    flex-direction: row;
  
    &__item {
      width: 33.33%;
    }
  }

  .testimonials {
    padding: 50px 0;
  
    .container {
      position: relative;
  
      &::after {
        display: block;
        clip-path: polygon(7% 0, 100% 0, 100% 190%, 0 82%);
      }
    }

    &__content {
      display: flex;
      flex-direction: row-reverse;
    
      &__video {
        width: 50%;
        border-right: 1px solid #ccc;
        margin-right: 15px;
        padding: 48px 20px 0 0;

        iframe {
          height: 215px;
          width: 100%;
        }
      }
    
      &__slide {
        width: 70%;
        padding: 45px 0 0;
        border-bottom: none;
        margin-bottom: 0;
        margin-right: 100px;
      }
    }
  
    .item {
      width: 70%;
    }

    blockquote {
      min-height: 144px;
    }
  }

  .carousel-indicators {
    bottom: -50px;
  }
  
  .testimonial-author {
    &__img {
      margin-right: -50px;
    }
  }

  .dedicated-hire {
    padding: 100px 0;
  
    &__content {
      flex-direction: row;
  
      &__item {
        text-align: center;
  
        &:first-child {
          margin: 0 25px 0 0;
          padding: 0 25px 0 0;
          border-right: 2px dashed #000;
          border-bottom: 0;
        }
      }
    }
  }

  .two-column-layout {
    &__item {
      .container {
        flex-direction: row;
        align-items: flex-start;
      }

      &__img {
        width: 50%;
        margin: 0 30px 0 0;

        &--strength-image {
          margin: -90px 30px 50px -60px;
        }

        &--even {
          order: 1;
          margin: 0 0 0 30px;
        }
      }
  
      &__content {
        width: 50%;
        
        &__wrap {
          &__item {
            &__heading {
              font-size: 28px;
              text-transform: uppercase;
              color: #000;
              font-weight: 600;
              padding-left: 222px;
              position: relative;
  
              &:before {
                content: "";
                width: 10px;
                height: 10px;
                background: #000;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
  
              &:after {
                content: "";
                border-bottom: 1px dashed #000;
                width: 210px;
                height: 1px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
  }

  .strength-bg {
    background-size: cover;
    padding-bottom: 10px;
  }

  .value-statements {
    &__text {
      font-size: 25px;
    }
  }

  .clients {
    &__cards {
      width: 80%;
      flex-direction: row;
  
      &__item {
        width: 48%;

        &__content-wrap {
          &__text {
            margin-bottom: 30px;
            height: 220px;
          }
        }
      }
    }
  }

  .more-clients {
    &__logos {
      &__item {
        width: 33.33%;
      }
    }
  }

  .img-card {
    margin: 0 18px 20px;
  }

  //Events

  /*ROR-WORKSHOP*/
  
  .ror-workshop-note {
    width: 80%;
    margin: 0 auto;

      &__message {
        font-size: 25px;
        color: #fff;
        margin: 10px 0 0 15px;
      }
    }

    .ror-workshop-details {
      &__item {
        &__images {
          height: auto;
        }
      }
    }

  /*ROR-WORKSHOP*/

  .event-participants {
    &__participants {
      width: 824px;
      justify-content: flex-start;

      &--tpo-participants {
        width: 830px;
        justify-content: flex-start;
      }
    }
  }

  //Start HR-Meet

  .event-details-hr-meet {
    &__felicitation {
      &__speech {
        &__item {
          flex-direction: row;
        }
      }
    }
  }

  //End HR-Meet

  //Start TPO Meet

  .speaker-discussion-points {
    flex-direction: row;
  }

  //End TPO Meet

  .event-speakers {
    &__speakers {
      flex-direction: row;

      &--hr-event-speaker {
        flex-direction: column;
      }

      &__item {
        margin: 0 0 0 15px;
        width: 50%;

        &--hr-speaker {
          margin: 0 0 15px;
          padding: 10px;
          width: 100%;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .speaker-speech-modal {
    width: 900px;
  }

  .business-speaker-details {
    flex-direction: row;
  
    &__content {
      margin-left: 15px;
  
      &__name {
        text-align: left;
      }
  
      &__designation {
        text-align: left;
      }
    }
  }

  //Events end

  /* Case Studies */
  .case-study {
    &__section {
      &__overview {
        flex-direction: row;

        &__main {
          width: 70%;
          padding-right: 15px;
          flex: 0 0 auto;
        }

        &__secondary {
          width: 30%;
          flex: 1 1 auto;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .site-header {
    &::after {
      clip-path: polygon(50% 0%, 100% 0, 100% 65%, 14% 100%, 0 74%, 0 0);
    }
  }

  .site-banner {
    &__content {
      font-size:16px;
    }

    &__img {
      &__foreground {
        top: 169px;
        left: 228px;
      }

      &--home {
        width: 584px;
        height: 469px;
      }

      &--clients, &--strength, &--sg {
        width: 584px;
      }

      &--contact {
        width: 540px;
        top:-73px;
      }

      &--bootcamp {
        width: 540px;
        top: 27px;
      }

      &--dedicated-development, &--software-development {
        width: 670px;
        top: 0;
      }
    }
  }

  .btn {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .circle-animation {
    display: block;
  }

  .site-footer__promotion {
    display: flex;

    &__information {
      flex: 1 1 auto;
      width: 50%;
    }

    &__reviews {
      padding-top: 0;
      display: flex;
      flex-direction: column;
      margin-left: 25px;
      padding-left: 12px;
      border-left: 1px solid #fff;
      align-items: center;

      &__content {
        margin-top: 30px;
        display: flex;
      }
    }
  }

  .site-banner__img--ror {
    width: 546px;
    top: 20px;
  }

  .two-column-layout {
    &__item__content__wrap__item__heading {
      font-size: 28px;
    }
  }

  .contact-footer {
    &__item {
      width: 34%;
    }
  }

  .india-flag, .us-flag {
    font-size: 16px;
  }

  //Events start

  // Start ROR WORSHOP

  .ror-workshop-details {
    &__item {
      &__images {
        height: auto;
      }
    }
  }

  // End ROR WORSHOP

  /* event landing */
  .event-landing {
    &__cards {
      flex-wrap: wrap;
      justify-content: space-evenly;

      &__item {
        margin-bottom: 15px;
        width: 30%;

        &__event-box__content--event-tag-line {
          font-size: 15px;
        }
      }
    }
  }
  /* event landing */

  .event-participants {
    &__participants {
      width: 1036px;
      justify-content: center;

      &--ceo-event-participants {
        padding-top: 0;
      }

      &--tpo-participants {
        width: 1036px;
      }
    }
  }

  //Start HR-meet

  .event-details-hr-meet {
    flex-direction: row;

    &__testimonials {
      max-width: 50%;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__welcome-section {
      max-width: 100%;
    }

    &__schedule {
      &__lists {
        width:100%;
        list-style-type: none;
        margin-left: 15px;

        &__item {
          background-size: 25px 25px;
          padding-left: 40px;
          background-position: 10px 16px;
          margin-bottom: 0;

          &__time {
            padding-right: 20px;
            display: inline-block;
          }

          &__content {
            color: #311b02;
            font-size: 14px;
            font-weight: 900;
          }

          &__icon {
            width: auto;
            display: inline-block;
          }

          &__speaker-points {
            padding: 5px 20px 0;
            list-style-type: none;
            margin-left: 0;
            max-width: 100%;

            &__item {
              margin-bottom: 20px;
              font-size: 14px;

              &:before {
                content: "";
                display: inline-block;
                background: #f79236;
                width: 10px;
                height: 10px;
                margin: 10px 7px 0px 2px;
              }

              &__title {
                margin-right: 15px;
                font-size: 15px;
                color: #055e7f;
              }
            }
          }
        }
      }
    }

    &__felicitation {
      &__speech {
        &__item {
          flex-direction: row;
        }
      }
    }
  }

  .event-details-hr-meet-content {
    padding: 0 80px;

    &__felicitation {

      &__speech {

        &__item {
          flex-direction: row;

          &__content {
            margin-left:15px;

            &__name {
              font-size: 20px;
            }
          }

          &__img {
            max-width: 150px;
            max-height: 150px;
            flex: 0 0 auto;
            margin:0;
          }
        }
      }
    }
  }

  .panel-terms {
    flex-direction: row;
  }

  //End HR-Meet

  /* Start TPO Meet */

  .tpo-event-details {
    flex-direction: row;

    .tpo-event-details__content, .tpo-event-details__testimonials {
      width: 50%;
    }

    &__content {
      margin: 0 0 0 25px;
      text-align: left;
    }

    &__testimonials {
      margin: 0 0 0 65px;
      flex-direction: row;
      flex-wrap: wrap;

      &__title {
        width: 100%;
      }

      &__item {
        background-color: #e8e8e8;
        padding: 15px;
        margin: 0 15px 15px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .speaker-discussion-points {
    flex-direction: row;
  }

  /* End TPO Meet */

  .event-speakers {
    &__speakers {
      &--hr-event-speaker {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      &__item{
        &--hr-speaker {
          margin: 0;
          width: 30%;
        }
      }
    }
  }

  //Start CEO Meet

  .event-details {
    flex-direction: row;

    &__left {
      width: 500px;
      order: 1;
    }

    &__right {
      order: 2;
      margin: 0 0 0 25px;
      text-align: left;
    }
  }

  //End CEO Meet


  //Business IT Meet
  .date-icon, .time-icon {
    padding: 15px 0 15px 80px;
    font-size: 22px;
  }

  .business-it-details {
    &__speakers {
      &__main {
        &__item {
          width: 24%;

          &--speech {
            width: 49%;
          }
        }
      }
    }
  }

  // CEO2 meet
  .event-time-venue {
    &__wrap {
      font-size: 25px;
      box-shadow: 5px 7px 10px -5px #000;
    }
  }
  // CEO2 meet
  
  //Events ends

  .privacy-policy {
    padding-top: 120px;
  }

  /* Case Studies */
  .case-study {
    font-size: 20px;

    &__section {
      &__heading {
        font-size: 42px;
      }

      &__solution {
        &__head__left, &__head__right {
          width: 45%;
        }

        &__row {
          &::before {
            content: url(../images/case_study_assets/bulb.png);
            position: absolute;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            left: 50%;
            z-index: 1;
          }
  
          &::after {
            content: "";
            position: absolute;
            width: 10%;
            height: 2px;
            border-bottom: 2px dashed #ccc;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
          }
  
          &--full-width::before, &--full-width::after {
            display: none;
          }

          &__left, &__right {
            width: 45%;
          }
        }
      }

      &__challenge__item__main__content__heading {
        font-size: 24px;
      }

      &__results__item__heading {
        font-size: 22px;
      }
    }
  }
}
