/* New CSS */
body {
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.p-relative {
  position: relative;
}

.btn {
  display: inline-block;
  background: linear-gradient(to bottom, #be68e5 0%, #9330c8 61%, #9330c8 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  padding: 5px 25px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  vertical-align: middle;
  margin-bottom: 10px;
  position: relative;
  z-index: 2;
  border: none;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }
}

.text-center {
  text-align: center;
}

.client-btn {
  background: linear-gradient(to bottom, #daa507 0%, #FF9800 61%, #FF9800 100%);
}

.strength-btn {
  background: linear-gradient(to bottom, #0abb9a 0%, #0abb9a 61%, #088c74 100%);
}

.dedicate-btn {
  background: linear-gradient(to bottom, #e6670c 0%, #ea6110 61%, #e46e08 100%);
}

.software-btn {
  background: linear-gradient(to bottom, #4ab5de 0%, #077bb3 61%, #0e7290 100%);
}

.container {
  width: 95%;
  margin: 0 auto;
  max-width: 1140px;
}

.home-heading {
  text-transform: uppercase;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  color: #143a7b;
  margin-bottom: 25px;
  font-family: 'Montserrat', sans-serif;

  &__text {
    position: relative;

    &--our-stats::after {
      content: "- THE DATA"
    }

    &--our-tech::after {
      content: "- WE WORK IN"
    }

    &--testimonials::after {
      content: "- TESTIMONIALS"
    }

    &--dedicated-hire::after {
      content: "- THE WORK"
    }

    &--our-strength::after {
      content: "- THE POWER"
    }

    &--dedicated-development::after {
      content: "- TEAM BEHIND"
    }

    &--software-development::after {
      content: "- THE DIFFERENTIATOR"
    }

    &--clients::after {
      content: "- THE SUCCESS"
    }

    &::after {
      position: absolute;
      top: -8px;
      left: 0;
      color: #cccccc;
      font-size: 12px;
    }

    &--white-color {
      color: #fff;
      display: block;
      padding-top: 30px;
    }
  }

  &__sub-text {
    display: block;
    font-size: 17px;
    font-weight: bold;
    color: #111111;
  }
}

.page-description {
  text-align: center;
  line-height: 2;
  font-size: 16px;
  margin-bottom: 50px;
}

.site-header {
  position: relative;
  background-image: url(../images/symbol-bg.png);

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #141848 0%, #15387c 50%, #15387c 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    position: absolute;
    left: 0;
    top: 0;
    top: 0;
    z-index: -1;
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    position: relative;

    &__brand {
      background: url("/images/kreeti_logo_no_tagline.png") no-repeat 0 0 / contain;
      display: inline-block;
      height: 40px;
      text-indent: -9999px;
      width: 220px;
      position: relative;
      z-index: 1;
    }

    &__menu-icon {
      background: url(/images/hamburger.png) no-repeat scroll center / 24px;
      display: inline-block;
      height: 44px;
      margin: 14px 12px 12px 30px;
      width: 44px;
      border-radius: 50%;
      position: relative;
      z-index: 3;
      cursor: pointer;
    }

    &__nav {
      display: none;
      position: absolute;
      z-index: 100000000;
      background: #ac45e0;
      width: 100%;
      padding: 15px;
      top: 66px;
      border-radius: 15px;

      &__item {
        position: relative;
        margin-left: 0;
        padding: 15px 10px;

        &__link {
          position: relative;
          padding-right: 17px;
          color: #ffffff;
          text-transform: uppercase;
          display: inline-block;

          &:hover {
            color: #fff;
            font-weight: 600;
          }

          &::after {
            content: "";
            display: block;
            height: 0;
            width: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 10px solid rgb(255, 255, 255);
            position: absolute;
            top: 5px;
            right: 0;
          }

          &--drop-link {
            padding-right: 0;
            margin: 0;

            &::after {
              content: "";
              display: block;
              height: 0;
              width: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 10px solid rgb(255, 255, 255);
              position: absolute;
              top: 5px;
              right: -18px;
            }
          }

          &--event-link {
            padding-right: 10px;

            &::after {
              display: none;
            }
          }
        }

        &__sub-menu {
          display: none;
          position: relative;
          top: 19px;
          right: 0;
          min-width: 65%;
          transition: opacity 0.1s linear;

          &__link {
            color: #ffffff;
            padding: 10px 15px;
            display: block;
            background: #6a1c91;
            border: 1px solid #fff;
            margin-bottom: -1px;
          }
        }
      }
    }
  }
}

.site-banner {
  color: #fff;
  padding: 50px 0;
  margin-bottom: 30px;

  &__heading {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 2px;
    text-transform: capitalize;
  }

  &__special-heading {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 16px;
  }

  &__content {
    font-size: 16px;
    line-height: 1.5;
    width: auto;

    &--small {
      font-size: 14px;
    }
  }

  &__highlight-text {
    background-color: #EFEC26;
    color: #000;
    font-size: 18px;
    font-weight: bold;
    margin: 5px 0px 30px;
    display: inline-block;
    padding: 9px 23px;
    border-radius: 20px;
  }

  &__img {
    width: 356px;
    height: auto;
    position: relative;

    &__foreground {
      position: absolute;
      top: 74px;
      left: 120px;
      opacity: 0;
      animation: ruby 15s 0s infinite;
    }
  }

  &__auct-info-note {
    width: 100%;
    padding: 10px;
    background-color: #e5f440;
    color: #000;
    display: inline-block;
    border-radius: 20px;
    text-align: center;
    font-weight: 600;
  }

  &__ceo-info {
    color: #e5f440;
    font-size: 24px;
  }
}

#particles-js {
  clip-path: polygon(50% 0%, 100% 0, 100% 65%, 22% 100%, 0 70%, 0 0);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.our-stats {
  padding: 100px 0 50px;
  position: relative;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 75px;

      &__count {
        font-size: 36px;
        font-weight: bold;
        color: #143a7b;
        line-height: 0.8;
      }

      &__subject {
        font-size: 12px;
        color: #555555;
        font-weight: bold;
      }

      &__video {
        height: 165px;
        width: auto;
        margin-bottom: 25px;
      }
    }
  }
}

.our-strength {
  background: #f6fafd url(/images/wave.webp) no-repeat left bottom / cover;
  padding: 50px 0;

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;

    &__text {
      flex: 1 1 auto;
      line-height: 2;
      font-size: 18px;
      margin-bottom: 50px;
    }

    &__img {
      position: relative;

      video {
        position: absolute;
        left: 0;
        top: 0;
        max-width: 100%;
      }
    }
  }
}

.our-clients {
  background: url(/images/handshake.webp) no-repeat center / contain, linear-gradient(45deg, #282775 0%, #6e2d93 55%, #6e2d93 100%);
  padding: 50px 0;
  overflow-x: hidden;

  &__heading {
    color: #fff;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 25px;
    font-family: 'Montserrat', sans-serif;
  }
}

.client-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;

  &__item {
    height: 74px;
    width: 75%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 60px;
    margin: 25px 0;
    margin: 25px auto;
    transition: all 0.1s linear;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 100%;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      transition: all 0.3s linear;
      z-index: -1;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
}

.carousel-control {
  background-position: center;
  background-size: 32px;
  opacity: 1;
  width: 30px;

  &.left {
    background-image: url(/images/left_arrow.png);
    background-repeat: no-repeat;
  }

  &.right {
    background-image: url(/images/right_arrow.png);
    background-repeat: no-repeat;
  }
}

.industry-prime-logo {
  background-image: url(/images/industry_prime_white.webp);

  &:hover {
    background-image: url(/images/industry_prime.png);
  }
}

.mandala {
  background-image: url(/images/mandala_logo_white.webp);

  &:hover {
    background-image: url(/images/mandala_logo.webp);
  }
}

.kylebunting {
  background-image: url(/images/kyle_bunting_white.webp);

  &:hover {
    background-image: url(/images/kyle_bunting.png);
  }
}

.lensculture {
  background-image: url(/images/lens_culture_white.webp);
  background-size: auto 30px;

  &:hover {
    background-image: url(/images/lens_culture.png);
  }
}

.smartgardener {
  background-image: url(/images/smartgardener_logo_white.webp);


  &:hover {
    background-image: url(/images/smartgardener_logo.webp);
  }
}

.clockwork {
  background-image: url(/images/clockwork_logo_white.webp);

  &:hover {
    background-image: url(/images/clockwork_logo.webp);
  }
}

.prats {
  background-image: url(/images/prats_logo_white.webp);

  &:hover {
    background-image: url(/images/prats_logo.webp);
  }
}

.fotomoto {
  background-image: url(/images/fotomoto_white.png);

  &:hover {
    background-image: url(/images/fotomoto.png);
  }
}

.healthblock {
  background-image: url(/images/healthblock_white.png);

  &:hover {
    background-image: url(/images/healthblock.png);
  }
}

.hipchip {
  background-image: url(/images/hipchip_white.png);

  &:hover {
    background-image: url(/images/hipchip.png);
  }
}

.lactaid {
  background-image: url(/images/lactaid_white.png);

  &:hover {
    background-image: url(/images/lactaid_new.png);
  }
}

.kudo {
  background-image: url(/images/kudo_logo_white.webp);
  background-size: auto 30px;

  &:hover {
    background-image: url(/images/kudo_logo.webp);
  }
}

.promio {
  background-image: url(/images/promio_logo_white.webp);

  &:hover {
    background-image: url(/images/promio_logo.webp);
  }
}

.real-real {
  background-image: url(/images/realreal_logo_white.webp);
  background-size: auto 30px;

  &:hover {
    background-image: url(/images/realreal_logo.webp);
  }
}

.testimonials {
  padding: 25px 0;

  .container {
    position: relative;

    &::after {
      content: "";
      display: none;
      position: absolute;
      left: 73%;
      top: -50px;
      background: url(/images/testimonial_bg.webp) repeat, linear-gradient(45deg, #150e38 0%, #15387c 55%, #15387c 100%);
      width: 200%;
      height: calc(100% + 100px);
      z-index: -1;
    }
  }

  &__content {
    &__video {
      iframe {
        height: 250px;
        width: 100%;
      }
    }

    &__slide {
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
      padding: 0 0 20px 0;
    }
  }

  blockquote {
    font-size: 16px;
  }
}

.carousel-indicators {
  bottom: 0px;
}

.carousel-indicators li {
  border: 2px solid #06295E;
}

.carousel-indicators .active {
  background-color: #06295E;
}

.testimonial-author {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__name {
    color: #009cf6;
    text-transform: uppercase;
  }

  &__img {
    width: 100px;
  }
}

.dedicated-hire {
  background: #efefef;
  padding: 50px 0;

  &-sub-head::after {
    content: "- THE WORK";
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    flex-direction: column;

    &__item {
      text-align: center;

      &:first-child {
        margin-bottom: 50px;
        padding-bottom: 50px;
        border-bottom: 2px dashed #000;
      }

      &__heading {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 25px;
        color: #143a7b;
      }

      &__img {
        display: block;
        margin: 50px 0;
      }
    }
  }
}

.site-footer {
  background: #143a7b;
  padding: 25px 0 25px;
  color: #fff;
  position: relative;
  overflow-x: hidden;
  margin-top: 30px;

  .footer-heading {
    font-size: 20px;
    color: #fff;
    margin-bottom: 10px;
  }

  &__promotion,
  &__promotion__reviews {
    padding-top: 25px;
  }

  &__promotion {

    &__information__member,
    &__reviews {
      &__content {
        display: flex;
        font-size: 16px;
        flex-direction: column;
      }
    }

    &__information {
      &__member {
        margin-bottom: 30px;

        &__content {
          background: #fff;
          padding: 5px;
          text-align: center;
          justify-content: space-between;

          &__item {
            margin-top: 25px;

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }

      .location-info__map {
        margin-top: 0;
      }
    }

    &__reviews {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }

  &__legal-info {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #fff;
    display: block;
    text-align: center;
    font-size: 12px;

    &__duns,
    &__policy {
      color: #fff;
      display: block;

      &:hover {
        color: #fff;
      }
    }
  }

  &__contact-info {
    &__content {
      display: flex;

      &__item {
        padding-right: 60px;

        &:first-child {
          margin-left: 0;
          padding-left: 0;
        }

        a {
          color: #fff;
          font-weight: bold;

          &:hover {
            color: #f5b168;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.contact-footer {
  flex-direction: column;
  display: flex;

  &__item {
    width: 100%;
  }
}

.location {
  &-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px;

    &__heading {
      font-weight: 600;
      color: #000;
      text-decoration: underline;

      &--white-left {
        color: #fff;
        text-align: left;
        text-decoration: none;
        margin-bottom: 5px;
      }
    }

    &__address {
      font-style: normal;
      font-size: 20px;
      font-weight: bold;

      &__link {
        color: #de903b;
        font-size: 14px;
      }

      &--small-size {
        font-size: 17px;
        text-align: left;
        font-weight: 400;
      }

      +.location-info__map {
        margin-top: 15px;
      }
    }

    &__map {
      border-radius: 16px;
      width: 100%;
      margin-top: 25px;
    }

    &__btn {
      display: inline-block;
      background: linear-gradient(to bottom, #be68e5 0%, #9330c8 61%, #9330c8 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      font-size: 14px;
      border-radius: 4px;
      color: #fff;
      padding: 5px 25px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
      vertical-align: middle;
      margin-bottom: 10px;
      position: relative;
      z-index: 2;
      width: 100%;
      margin: 30px auto;
    }
  }
}

.india-flag,
.us-flag {
  display: block;
  padding-left: 44px;
  margin-bottom: 15px;
  font-size: 16px;
}

.india-flag {
  background: url(/images/india_flag.jpg) no-repeat left 5px;
}

.us-flag {
  background: url(/images/us_flag.jpg) no-repeat left 5px;
}

.phone-img,
.mail-img,
.mobile-img {
  background-repeat: no-repeat;
  background-position: left top;
  color: #1f1f1f;
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  padding-left: 44px;
  padding-bottom: 10px;
}

.phone-img {
  background-image: url(/images/call_bc.png);
}

.mobile-img {
  background-image: url(/images/contact_mobile.png);
}

.mail-img {
  background-image: url(/images/email_bc.png);
  color: #f73404;

  a {
    color: #f73404;
    display: block;
  }
}

.contact-info-title {
  font-size: 18px;
  color: #06295E;
  margin-bottom: 7px;
  display: block;
  text-transform: uppercase;
}

.india,
.us {
  margin-bottom: 0;
  border-right: none;
  padding: 20px 20px 0;
  height: auto;
}

.us {
  border-right: none;
}

.spacer-item {
  display: none;
}

.clutch-widget {
  width: 211px;
}

.clients {
  padding-top: 50px;
  position: relative;

  &-sub-head::after {
    content: "- THE CLIENTS"
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-between;
    flex-direction: column;
    width: auto;

    &__item {
      display: flex;
      flex-direction: column;
      margin: 10px 0 20px 0;
      border: 1px solid #efefef;
      border-radius: 8px;
      box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
      padding: 20px;

      &__logo {
        height: 70px;
        text-align: center;
        flex: 0 0 auto;

        &__img {
          flex: 0 0 auto;
          max-height: 100%;
        }
      }

      &__content-wrap {
        flex: 1 1 auto;

        &__graphic {
          margin: 25px 0;
        }

        &__text {
          text-align: center;
          line-height: 2;
          font-size: 16px;
          color: #555;
        }

        &__technologies {
          margin-bottom: 20px;

          &__heading {
            font-size: 15px;
            font-weight: 800;
            text-transform: uppercase;
            margin-bottom: 20px;
            color: #555;
          }

          &__images {
            &__item {
              margin: 0 8px;
            }
          }
        }
      }

      &__link {
        font-size: 18px;
        font-weight: bold;
        color: #143a7b;
        text-align: center;
        display: block;
        flex: 0 0 auto;
      }
    }
  }
}

.more-clients {
  margin-top: 50px;
  position: relative;

  h3 {
    padding: 50px 0 30px;
  }

  &__logos {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &__item {
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto;
      display: block;
      height: 200px;
      line-height: 200px;
      text-indent: -9999px;
      transition: all 2s ease;
    }
  }
}

.penguin {
  background-color: #FF6600;
  background-image: url(/images/penguin.png);
}

.heartland {
  background-color: #EEEEEE;
  background-image: url(/images/heartland.png);
}

.johnson {
  background-color: #AC0000;
  background-image: url(/images/johnson.png);
}

.Kudo {
  background-color: #fafafa;
  background-image: url(/images/kudo_logo.webp);
}

.tox {
  background-color: #06295E;
  background-image: url(/images/tox.png);
}

.rocket {
  background-color: #3DC5D8;
  background-image: url(/images/rocket.png);
}

.mesh {
  background-color: #333333;
  background-image: url(/images/mesh.png);
}

.memorials {
  background-color: #AECF31;
  background-image: url(/images/memorials.png);
}

.healthcare {
  background-color: #33A6D6;
  background-image: url(/images/healthcare.png);
}

.webficient-client {
  background-color: #25E3AD;
  background-image: url(/images/webficient.png);
}

.foto {
  background-color: #000000;
  background-image: url(/images/foto.png);
}

.lactaid-client {
  background-color: #65D427;
  background-image: url(/images/lactaid.png);
}

.belly {
  background-color: #9B4EA0;
  background-image: url(/images/belly_logo.png);
}

.tastebook {
  background-color: #f65158;
  background-image: url(/images/tastebook_white.png);
  background-size: 200px;
}

.lensculture-client {
  background-color: #baa222;
  background-image: url(/images/lens_culture_white.webp);
  background-size: 200px;
}

.hipchip-client {
  background-color: #28324b;
  background-image: url(/images/hipchip_white.png);
  background-size: 200px;
}

.kylebunting-client {
  background-color: #6e79c8;
  background-image: url(/images/kyle_bunting_white.webp);
  background-size: 200px;
}

.collector-client {
  background-color: #48644b;
  background-image: url(/images/collector_revolution_custom.png);
  background-size: 200px;
}

.two-column-layout {

  &--our-strength,
  &--dedicated-development,
  &--software-development {
    padding-top: 50px;
  }

  &__item {
    margin-bottom: 150px;
    position: relative;

    &:last-child:not(.two-column-layout__item--our-strength) {
      margin-bottom: 50px;
    }

    .container {
      display: flex;
      flex-direction: column;
    }

    &__flying-man {
      position: relative;
    }

    &__img {
      flex: 1 1 auto;
      margin-bottom: 50px;
      text-align: center;
    }

    &__content {
      flex: 0 0 auto;

      &__wrap {
        &__item {
          &__heading {
            font-size: 26px;
            text-transform: uppercase;
            color: #000;
            font-weight: 600;
          }
        }
      }

      &__heading {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #143a7b;
        font-family: 'Montserrat', sans-serif;
      }

      &--white-bg {
        background-color: #fff;
        border-radius: 30px;
        padding: 40px 30px;
        z-index: 2;
      }

      &__paragraph {
        font-size: 16px;
        line-height: 2;

        +.two-column-layout__item__content__paragraph {
          margin-top: 25px;
        }
      }
    }
  }
}

.strength-bg {
  background: url("/images/strength-bg.jpg") repeat 0 0 / contain;
}

.circle-animation {
  display: none;
  position: absolute;
  width: 200px;
  z-index: -1;
  animation: circle_scale 3s linear infinite;

  &-right {
    right: 0;
    top: 0;
  }

  &-left {
    left: 0;
    top: 0;
  }

  &-bottom-left {
    bottom: 0;
    left: 0;
  }
}

.fly-left {
  width: 100px;
  position: absolute;
  right: 0;
  top: 0;
  animation: fly_left 50s linear infinite;
}

.fly-right {
  width: 100px;
  position: absolute;
  left: 0px;
  top: 0;
  animation: fly_right 50s linear infinite;
}

.ready-to-scale {
  text-align: center;
  padding-top: 50px;
  border-top: 1px solid #ccc;

  &__heading {
    font-size: 32px;
  }

  &__description {
    margin-bottom: 25px;
  }

  .btn--ready-to-scale {
    font-size: 22px;
    font-weight: bold;
  }
}

.scroll-top {
  position: fixed;
  right: 0px;
  bottom: 150px;
  padding: 20px;
  transition: all 0.4s ease-in-out 0s;
  width: 90px;
  opacity: 0.9;
  display: none;
  cursor: pointer;
}

.contact-floating-btn {
  position: fixed;
  right: -15px;
  bottom: 40px;
  padding: 20px;
  width: 120px;
}

.social-bar {
  position: fixed;
  bottom: 150px;
  transform: translateY(-50%);
  z-index: 4;
  background-color: #fff;
  padding: 20px 5px;
  border-radius: 40px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  left: 10px;

  &__link {
    display: block;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }

    &__img {
      height: 24px;
      width: 24px;
    }
  }
}

.hbspt-form {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  iframe {
    min-height: 80px;
  }

  &::after {
    content: "";
    display: block;
    background-color: #fff;
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

/* Animations */
@-webkit-keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@-webkit-keyframes fly-right-one {
  0% {
    left: -10%;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }

  10% {
    left: 10%;
    -webkit-transform: translateY(2vh) scale(0.4);
    transform: translateY(2vh) scale(0.4);
  }

  20% {
    left: 30%;
    -webkit-transform: translateY(0vh) scale(0.5);
    transform: translateY(0vh) scale(0.5);
  }

  30% {
    left: 50%;
    -webkit-transform: translateY(4vh) scale(0.6);
    transform: translateY(4vh) scale(0.6);
  }

  40% {
    left: 70%;
    -webkit-transform: translateY(2vh) scale(0.6);
    transform: translateY(2vh) scale(0.6);
  }

  50% {
    left: 90%;
    -webkit-transform: translateY(0vh) scale(0.6);
    transform: translateY(0vh) scale(0.6);
  }

  60% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.6);
    transform: translateY(0vh) scale(0.6);
  }

  100% {
    left: 110%;
    opacity: 1;
    -webkit-transform: translateY(0vh) scale(0.6);
    transform: translateY(0vh) scale(0.6);
  }
}

@keyframes fly-right-one {
  0% {
    left: -10%;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }

  10% {
    left: 10%;
    -webkit-transform: translateY(2vh) scale(0.4);
    transform: translateY(2vh) scale(0.4);
  }

  20% {
    left: 30%;
    -webkit-transform: translateY(0vh) scale(0.5);
    transform: translateY(0vh) scale(0.5);
  }

  30% {
    left: 50%;
    -webkit-transform: translateY(4vh) scale(0.6);
    transform: translateY(4vh) scale(0.6);
  }

  40% {
    left: 70%;
    -webkit-transform: translateY(2vh) scale(0.6);
    transform: translateY(2vh) scale(0.6);
  }

  50% {
    left: 90%;
    -webkit-transform: translateY(0vh) scale(0.6);
    transform: translateY(0vh) scale(0.6);
  }

  60% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.6);
    transform: translateY(0vh) scale(0.6);
  }

  100% {
    left: 110%;
    opacity: 1;
    -webkit-transform: translateY(0vh) scale(0.6);
    transform: translateY(0vh) scale(0.6);
  }
}

@-webkit-keyframes fly-right-two {
  0% {
    left: -10%;
    opacity: 1;
    -webkit-transform: translateY(-2vh) scale(0.5);
    transform: translateY(-2vh) scale(0.5);
  }

  10% {
    left: 10%;
    -webkit-transform: translateY(0vh) scale(0.4);
    transform: translateY(0vh) scale(0.4);
  }

  20% {
    left: 30%;
    -webkit-transform: translateY(-4vh) scale(0.6);
    transform: translateY(-4vh) scale(0.6);
  }

  30% {
    left: 50%;
    -webkit-transform: translateY(1vh) scale(0.45);
    transform: translateY(1vh) scale(0.45);
  }

  40% {
    left: 70%;
    -webkit-transform: translateY(-2.5vh) scale(0.5);
    transform: translateY(-2.5vh) scale(0.5);
  }

  50% {
    left: 90%;
    -webkit-transform: translateY(0vh) scale(0.45);
    transform: translateY(0vh) scale(0.45);
  }

  51% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.45);
    transform: translateY(0vh) scale(0.45);
  }

  100% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.45);
    transform: translateY(0vh) scale(0.45);
  }
}

@keyframes fly-right-two {
  0% {
    left: -10%;
    opacity: 1;
    -webkit-transform: translateY(-2vh) scale(0.5);
    transform: translateY(-2vh) scale(0.5);
  }

  10% {
    left: 10%;
    -webkit-transform: translateY(0vh) scale(0.4);
    transform: translateY(0vh) scale(0.4);
  }

  20% {
    left: 30%;
    -webkit-transform: translateY(-4vh) scale(0.6);
    transform: translateY(-4vh) scale(0.6);
  }

  30% {
    left: 50%;
    -webkit-transform: translateY(1vh) scale(0.45);
    transform: translateY(1vh) scale(0.45);
  }

  40% {
    left: 70%;
    -webkit-transform: translateY(-2.5vh) scale(0.5);
    transform: translateY(-2.5vh) scale(0.5);
  }

  50% {
    left: 90%;
    -webkit-transform: translateY(0vh) scale(0.45);
    transform: translateY(0vh) scale(0.45);
  }

  51% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.45);
    transform: translateY(0vh) scale(0.45);
  }

  100% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.45);
    transform: translateY(0vh) scale(0.45);
  }
}

@keyframes ruby {
  0% {
    opacity: 1;
    transform: perspective(250px) translateX(-30px) translateY(50px) translateZ(-500px);
  }

  50% {
    opacity: 1;
    transform: perspective(250px) translateX(0px) translateY(0) translateZ(0px);
  }

  100% {
    opacity: 1;
    transform: perspective(250px) translateX(-30px) translateY(50px) translateZ(-500px);
  }
}

@keyframes fly_right {
  0% {
    transform: rotate(0), translateX(0);
  }

  10% {
    transform: rotate(0deg), translateX(12vw);
  }

  20% {
    transform: rotate(0deg), translateX(24vw);
  }

  30% {
    transform: rotate(0deg) translateX(36vw);
  }

  40% {
    transform: rotate(0deg), translateX(48vw);
  }

  50% {
    transform: rotate(-1deg) translateX(60vw);
  }

  60% {
    transform: rotate(-2deg) translateX(72vw);
  }

  70% {
    transform: rotate(-3deg) translateX(84vw);
  }

  80% {
    transform: rotate(-4deg) translateX(96vw);
  }

  90% {
    transform: rotate(-5deg) translateX(108vw);
  }

  100% {
    transform: rotate(-6deg) translateX(200vw);
  }
}

@keyframes fly_left {
  0% {
    transform: rotate(0deg), translateX(0);
  }

  10% {
    transform: rotate(0deg), translateX(-12vw);
  }

  20% {
    transform: rotate(0deg), translateX(-24vw);
  }

  30% {
    transform: rotate(0deg) translateX(-36vw);
  }

  40% {
    transform: rotate(0deg), translateX(-48vw);
  }

  50% {
    transform: rotate(1deg) translateX(-60vw);
  }

  60% {
    transform: rotate(2deg) translateX(-72vw);
  }

  70% {
    transform: rotate(3deg) translateX(-84vw);
  }

  80% {
    transform: rotate(4deg) translateX(-96vw);
  }

  90% {
    transform: rotate(5deg) translateX(-108vw);
  }

  100% {
    transform: rotate(6deg) translateX(-200vw);
  }
}

@keyframes circle_scale {
  0% {
    transform: scale(0.5);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0.5);
  }
}

.benefits-bg {
  background-color: #f7f7f7;
  padding: 20px 0;
  margin-bottom: 30px;
}

.benefits_wrap {
  display: flex;
  flex-wrap: wrap;

  &__item {
    max-width: 100%;
    margin: 0 auto 40px;
    flex: 0 0 auto;
    text-align: center;

    &__heading {
      font-size: 22px;
      padding: 10px 0;
      font-weight: 600;
    }
  }
}


.ai-wrap {
  display: flex;
  flex-wrap: wrap;

  &__item {
    margin: 0 auto 40px;
    text-align: center;

    &__heading {
      font-size: 35px;
      padding: 10px 0;
      color: #143a7b;
      font-weight: 300;
    }
  }
}

.value-statements {
  background: #7c283f;
  background: linear-gradient(to bottom, #7c283f 21%, #45325f 96%);
  padding: 20px 0;
  margin-bottom: 30px;
  text-align: center;

  &--ai-bg {
    background: url(/images/ai-bg.webp) no-repeat left top;
  }

  &__heading {
    font-size: 50px;
    font-weight: 600;
    color: #ffffff;
    text-transform: uppercase;
    text-shadow: 2px 2px #000000;
  }

  &__text {
    font-size: 21px;
    color: #ffffff;
  }
}

.services {
  background: #f7f7f7 url(/images/bg_dot.webp) no-repeat left top;
  padding: 30px 0;
  text-align: center;
  margin-bottom: 30px;

  &_wrap {
    width: 80%;
    margin: 0 auto;

    &__item {
      border-color: #632c4d;
      border-radius: 0 20px 0 20px;
      border-width: 0px 0px 8px 8px;
      border-style: solid;
      height: auto;
      position: relative;
      width: 100%;
      margin: 0 auto;
      text-align: left;

      &:last-child {
        border-bottom: none;
      }

      &--no-border-circle {
        border-radius: 0 20px 0 0px;
      }

      &--right {
        border-radius: 0 20px 20px 0;
        border-width: 8px 8px 8px 0;
        margin-top: -8px;
        margin-right: -16px;
        text-align: right;
        padding-right: 40px;

        &:last-child {
          border-radius: 0 20px 0 0;
        }
      }

      &__number {
        background: #7c283f;
        background: linear-gradient(to bottom, #7c283f 21%, #45325f 96%);
        color: #ffffff;
        font-size: 40px;
        text-align: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: -27px;
        left: -35px;

        &--right {
          right: -32px;
          left: auto;
          top: auto;
          bottom: 0;
        }

        &--right-top {
          right: -32px;
          left: auto;
        }
      }

      &__heading {
        font-size: 38px;
        color: #000000;
        padding: 20px 0 0 40px;
        font-weight: 600;
        line-height: 45px;
        margin-bottom: 15px;
        text-transform: capitalize;
      }

      &__text {
        padding-left: 40px;
        font-size: 20px;
      }
    }
  }
}

.tech-page-testimonials {
  width: 100%;
  margin: 0 auto;
  padding: 2px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  iframe {
    height: 400px;
    width: 100%;
  }

  .btn {
    margin-top: 20px;
  }
}

.technologies-sec {
  padding: 50px 0;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__label {
    order: 1;
    display: block;
    padding: 10px 20px;
    margin-right: 12px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
  }

  &__tab {
    order: 99;
    flex-grow: 1;
    width: 100%;
    display: none;
    padding: 10px;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__input {
    display: none;

    &:checked+.tabs__label {
      color: #143a7b;
      border-bottom: 2px solid #143a7b;
      display: block;
      font-weight: 600;
      font-size: 22px;
    }

    &:checked+.tabs__label+.tabs__tab {
      display: flex;
    }
  }
}

.img-card {
  text-align: center;
  padding: 0 0;
  background: #f7f7f7;
  border-radius: 8px;
  margin: 0 10px 20px;
  float: left;

  &__btn {
    display: block;
    background: #143a7b;
    color: #fff;
    text-transform: uppercase;
    padding: 8px 5px;
    font-family: poppins, sans-serif;
    font-size: 16px;
    line-height: 15px;
    border-radius: 0 0 6px 6px;
  }
}

@keyframes move {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

.bootcamp {
  &-welcome {
    font-size: 22px;
    text-align: center;

    &__bold {
      color: #000000;
      font-weight: bold;
    }
  }

  &-information {
    padding: 50px 0;
  }

  &-tab {
    font-size: 18px;
    border-top: 1px solid #cccccc;
    justify-content: start;

    &__points {
      &__item {
        margin-bottom: 25px;
        position: relative;
        padding-left: 20px;

        &__sub-points {
          margin-left: 30px;
          list-style-type: disc;
          margin-top: 10px;
        }
      }
    }
  }
}

/* Events*/

.event-info-container {
  width: 95%;
  margin: 0 auto;
}

/* event landing */

.event-landing {
  font-size: 13px;
  padding: 15px 0 15px;

  &__cards {
    display: flex;
    flex-wrap: wrap;

    &__item {
      background-color: #ddd;
      margin-bottom: 15px;
      padding: 10px;
      text-align: center;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      width: 100%;

      &__event-box {
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s;
        position: relative;
        color: #fff;
        padding: 85px 0 25px;
        font-size: 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.tpo-event {
          background-image: url("/images/tpo.jpg");
        }

        &.ROR-event {
          background-image: url("/images/ror_workshop.jpg");
        }

        &.hr-meet {
          background-image: url("/images/Hr-meet-landing.jpg");
        }

        &.business-it {
          background-image: url("/images/business_it_landing.jpg");
        }

        &.auction {
          background-image: url("/images/auction.jpg");
        }

        &.ceo2-meet {
          background-image: url("/images/ceo2_meet.jpg");
        }

        &.elixir-phoenix-bootcamp {
          background-image: url("/images/elixir-training.jpg");
        }

        &.ceo3-meet {
          background-image: url("/images/ceo3_meet.jpg");
        }

        &.ceo4-meet {
          background-image: url("/images/ceo4_meet.jpg");
        }

        &:before {
          content: "";
          display: block;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.6);
        }

        &__content {
          display: block;
          position: relative;

          &--coming-soon-title {
            position: relative;
            top: -40px;
            color: #84ff10;
            font-weight: 600;
          }

          &--event-title {
            margin-top: 20px;
            font-weight: 700;
            font-size: 25px;
            color: #f5a956;
          }

          &--event-date {
            margin-bottom: 20px;
            font-weight: 600;
            font-size: 18px;
          }

          &--event-tag-line {
            font-style: italic;
            font-size: 18px;
          }
        }
      }

      &:hover &__event-box {
        -ms-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -webkit-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
      }
    }
  }
}

.ceo-event {
  background-image: url("/images/photogallery_lightbox/photo_gal33.jpg");
}

/* event landing */

.event-participants {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__item {
    text-align: center;
    padding: 15px;
    margin: 0 0 14px;
    width: 206px;
    transition: all 0.5s ease 0s;

    &--item-border {
      border: 1px solid #ccc;
      margin: 10px;
    }

    &:hover {
      box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
    }

    &__img {
      height: 60px;

      &--ceo-event {
        height: 100px;
      }
    }

    &__name,
    &_-designation {
      display: block;
    }

    &__name {
      font-weight: bold;
      color: #000;
      margin-top: 5px;
    }
  }

  &__participants {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 206px;
    justify-content: flex-end;

    &--ceo-event-participants {
      justify-content: center;
      padding-top: 100px;
    }

    &__item {
      text-align: center;
      padding: 15px;
      margin: 0 0 14px;
      width: 206px;
      transition: all 0.5s ease 0s;

      &--item-border {
        border: 1px solid #ccc;
        margin: 10px;
      }

      &:hover {
        box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
      }

      &__img {
        height: 60px;

        &--ceo-event {
          height: 100px;
        }
      }

      &__name,
      &_-designation {
        display: block;
      }

      &__name {
        font-weight: bold;
        color: #000;
        margin-top: 5px;
      }

      &__details {
        margin: 10px 0 0;
      }

      &--more-text {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

/*ROR-Workshop*/

.ror-workshop-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 70px;

  &__title {
    width: 100%;
    font-size: 26px;
    color: #000;
    font-weight: bold;
    text-align: center;
  }

  &__item {
    text-align: center;
    width: 270px;
    padding: 0 10px;

    &__images {
      height: 243px;
      display: block;
    }

    &__content {
      display: block;
      font-size: 20px;
      color: #000;
      font-weight: 600;
    }
  }
}

.ror-workshop-note {
  background: #560b07;
  padding: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;

  &__image {
    flex: 0 0 auto;
    max-height: 64px;
  }

  &__message {
    font-size: 16px;
    color: #fff;
    margin: 0px 0 0 15px;
  }
}

/*ROR-WORKSHOP*/

/*AUCTION-1*/
.kalpavriksha1-intro {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  color: #000;

  &__title {
    font-size: 40px;
    margin-bottom: 0;
    font-weight: bold;
    margin-right: 15px;
    line-height: 1;
  }

  &__content {
    font-size: 20px;
  }
}

.statistics-bg {
  background-color: #f2f2f2;
  padding: 20px 0;
}

.statistics-content {
  &__advantages {
    &__heading {
      font-size: 25px;
      color: #152862;
      font-weight: 600;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    &__content {
      display: flex;
      justify-content: space-around;
      flex-direction: column;

      &__item {
        width: 100%;
        padding: 20px;
        text-align: center;

        &__count {
          display: inline-block;
          width: 130px;
          height: 130px;
          border-radius: 50%;
          border: 4px solid #ed9014;
          background-color: #fff;
          color: #000;
          font-size: 28px;
          text-align: center;
          padding-top: 40px;
          font-weight: 600;
        }

        &__count-text {
          font-size: 20px;
          color: #000;
          font-weight: 500;
          display: block;
          padding-top: 10px;
        }
      }
    }
  }
}

.content-title {
  font-size: 40px;
  color: #000;
  font-weight: 400;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.testimonial-content {
  &__wrap {
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    &__item {
      width: 100%;
      text-align: center;
      border-top: 1px solid #5d5d5d;
      border-right: 1px solid #5d5d5d;
      border-left: 1px solid #5d5d5d;
      border-bottom: 9px solid #2c5592;
      padding: 20px;
      border-radius: 20px;
      margin-bottom: 20px;

      &__image {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        display: inline-block;
      }

      &__name {
        display: block;
        font-weight: 600;
        font-size: 18px;
        color: #000;
      }

      &__designation {
        display: block;
        margin-bottom: 20px;
      }

      &__quotes {
        font-size: 16px;
        font-style: italic;
      }
    }
  }
}

/*AUCTION-1*/

/*CEO2-Meet*/
.ceo-event-meet-details {
  padding-top: 160px;

  &__heading {
    font-size: 24px;
    font-weight: bold;
    color: #000;
    line-height: 1;
    text-transform: uppercase;
    padding-top: 25px;
    margin-bottom: 40px;
  }

  &__content {
    font-size: 20px;
    text-align: center;

    &--alignment-para {
      text-align: left;
      padding-top: 30px;
    }

  }

  &__statement {
    font-size: 30px;
    color: #bb0672;
    text-align: center;
    display: block;
    padding-top: 30px;
  }

  &__conference {

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__img {
        width: 70%;
      }

      &__wrap {
        padding: 0;

        &__heading {
          font-size: 24px;
          font-weight: bold;
          color: #000;
          line-height: 1;
          padding-top: 25px;
          margin-bottom: 40px;
        }

        &__list {
          &__item {
            font-size: 20px;
            line-height: 1.5;
            margin-bottom: 25px;
            position: relative;
            padding-left: 20px;

            &::after {
              content: "";
              background-image: url(/images/arrow.png);
              position: absolute;
              left: 0;
              top: 6px;
              width: 15px;
              height: 19px;
            }
          }
        }
      }
    }
  }
}

.ceo2-meet-details__conference__agenda-wrap__list__item,
.bootcamp-tab__points__item {
  &:after {
    content: "";
    background-image: url(/images/arrow.png);
    position: absolute;
    left: 0;
    top: 6px;
    width: 15px;
    height: 19px;
  }
}

.event-time-venue {
  position: relative;

  &__wrap {
    position: absolute;
    background-color: #770904;
    padding: 15px;
    right: 4px;
    top: 70px;
    color: #fff;
    text-align: right;
    font-size: 18px;
    box-shadow: 5px 7px 10px -5px #000;
  }
}

.event-venue {
  width: 100%;
  text-align: center;
  margin: 0 auto;

  &__address {
    padding: 0 10px;
    font-size: 25px;

    &__title {
      font-size: 35px;
    }

    &__date,
    &__place {
      display: block;
      color: #000000;
    }

    &__date {
      margin: 3px 0;
      font-weight: bold;
    }

    &__place {
      margin-bottom: 20px;

      &__name,
      &__time {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }

  &__hotel-wrap {
    display: flex;
    flex-direction: column;

    &__img {
      width: 100%;
    }
  }
}

.ceo-meet2-discussion {
  padding: 10px 0;

  &__content {
    &__item {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 30px;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &:last-child {
        border: none;
      }

      &__img {
        margin-bottom: 20px;
        box-shadow: 0px 5px 13px 0px #707070;
      }

      &__details {
        font-size: 16px;

        &__heading {
          margin-bottom: 15px;

          &__name {
            font-size: 20px;
            display: block;
            font-weight: 600;
          }
        }
      }
    }
  }
}

/*CEO2-Meet*/

/*CEO3-Meet*/
.ceo-meet-discussion {
  padding: 10px;
  background-color: #f5f5f5;

  &__content {
    &__item {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 30px;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &:last-child {
        border: none;
      }

      &__img {
        margin-bottom: 20px;
        box-shadow: 0px 5px 13px 0px #707070;
      }

      &__details {
        font-size: 16px;

        &__heading {
          margin-bottom: 15px;

          &__name {
            font-size: 20px;
            display: block;
            font-weight: 600;
          }
        }

        &__points {
          list-style-type: disc;
          padding-left: 15px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

/*CEO3-Meet*/

// Gallery photo lightbox image hover -12-02-2019
.photo-gallery-image-item {
  width: 100%;
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;

  .img-wrapper {
    position: relative;
    margin-top: 15px;

    .img-overlay {
      background: rgba(0, 0, 0, 0.7);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
    }
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

#photo_gallery_overlay {
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  img {
    margin: 0;
    width: 80%;
    height: auto;
    object-fit: contain;
    padding: 5%;
  }
}

#nextButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;

  &:hover {
    opacity: 0.7;
  }
}

#prevButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;

  &:hover {
    opacity: 0.7;
  }
}

#exitButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
  position: absolute;
  top: 15px;
  right: 15px;

  &:hover {
    opacity: 0.7;
  }
}

//End Gallery photo lightbox image hover -12-02-2019


/* start HR-Meet*/
.support-nasscom {
  margin-bottom: 20px;

  &__text {
    display: block;
    font-size: 15px;
    font-weight: 700;
    color: 30px;
  }

  &__img {
    max-width: 25%;
    background: #fff;
    padding: 10px;
  }
}

.event-details-hr-meet {
  display: flex;
  flex-direction: column;

  .heading {
    font-size: 25px;
    color: #035c71;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
    width: 100%;
  }

  &__welcome-section {
    max-width: 100%;

    &__content {
      font-size: 16px;
      line-height: 30px;
      color: #fff;
      padding: 20px;
    }
  }

  .welcome-hr {
    background: #9c27b0;
  }

  .strategic-hr {
    background: #e6296a;
  }

  .success-hr {
    background: #2aa1d8;
  }

  .professional-hr {
    background: #C27428;
  }

  &__schedule {
    width: 100%;

    &__lists {
      list-style-type: none;
      margin: 0;
      padding: 0;

      &__item {
        background: url("/images/clock.png") no-repeat 10px 2px / 15px 15px;
        padding-left: 29px;
        margin-bottom: 10px;

        &__time {
          padding-right: 20px;
          width: auto;
          display: block;
        }

        &__content {
          font-weight: 900;
          color: #000;
        }

        &__icon {
          display: none;
        }

        &__speaker-points {
          padding: 5px 10px 0;
          list-style-type: none;
          margin-left: 0;

          &__item {
            margin-bottom: 20px;
            font-size: 16px;
            display: flex;

            &:before {
              content: "";
              display: inline-block;
              background: #f79236;
              width: 10px;
              height: 10px;
              margin: 10px 7px 0px 2px;
              flex: 0 0 auto;
            }

            &__image-sec {
              width: 70px;
              overflow: hidden;
              margin-right: 10px;
              flex: 0 0 auto;

              &__img {
                border-radius: 50%;
                border: 2px solid #DE903B;
              }
            }

            &__content {
              flex: 1 1 auto;

              &__name {
                color: #035c71;
              }

              &__topics {
                margin-bottom: 10px;
              }

              &__company-name {
                color: #000;
                font-weight: 700;
                font-style: italic;
              }

              &__special-guest-text {
                color: #DE903B;
                font-size: 15px;
                text-transform: uppercase;
                display: inline-block;
              }
            }

            &__title {
              margin-right: 15px;
              font-size: 15px;
              color: #055e7f;
            }

            &__content {
              color: #5f5c58;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  &__felicitation {
    width: 90%;
    padding-left: 10px;
    margin: 0 auto;

    &__message {
      display: block;
      text-align: center;
      font-size: 25px;
      color: #bb3c00d1;
      font-weight: 600;
    }

    &__pictures {
      margin-top: 10px;
      text-align: center;

      &__img {
        margin: 0 5px 10px;
        border: 4px solid #ec8c23;
      }
    }

    &__speech {
      margin: 10px;

      &__heading {
        font-size: 25px;
        color: #045377;
        font-weight: 600;
        border-bottom: 3px solid #045377;

        &--guest-speech {
          text-align: right;
        }
      }

      &__item {
        display: flex;
        flex-direction: column;

        &--mobile-structure-reverse {
          flex-direction: column-reverse;
        }

        &__content {
          margin-left: 15px;

          &__name {
            display: block;
            font-size: 18px;
            font-weight: 600;
            color: #0f4159;
            text-transform: uppercase;

            &__designation {
              color: #c27428;
            }
          }

          &__company {
            color: #000;
            font-weight: 600;
            margin-bottom: 10px;
            display: block;
          }
        }

        &__img {
          max-width: 150px;
          max-height: 150px;
          flex: 0 0 auto;
          margin: 0 auto;
        }
      }
    }
  }

  &__testimonials {
    max-width: 100%;

    &__main-section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &__item {
        background-color: #e8e8e8;
        flex: 1 1 0;
        padding: 15px;
        margin: 0 5px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__profile-pic {
          border-radius: 50%;
          max-width: 120px;
          max-height: 120px;
        }

        &__profile-name {
          font-weight: 700;
          color: #000;
          font-size: 15px;
        }

        &__designation {
          font-weight: 700;
          color: #b16109;
          font-size: 11px;
        }

        &__company-name {
          height: 40px;
          font-size: 13px;
          font-weight: 600;
          color: #000;
          margin-bottom: 10px;
        }

        &__content {
          text-align: center;
          font-size: 15px;

          q {
            font-style: italic;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.hr-event-speaker {
  margin-bottom: 40px;
}

.hr-pain-points {
  &__heading {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    color: #015896;
    text-transform: uppercase;
  }

  &__contents-wraper {
    display: flex;
    flex-direction: column;

    &__hr-points {
      &__title {
        font-size: 20px;
        text-transform: uppercase;
        color: #ffffff;
        font-weight: 600;
        background: #084f71;
        display: inline-block;
        padding: 10px;
      }

      &__details {
        margin-bottom: 10px;
        list-style-type: none;

        &__item {
          margin-bottom: 10px;
          display: flex;
          margin-top: 30px;

          &__img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            -moz-border-radius: 50%;
            border: 2px solid #1097d8;
          }

          &__content-wrapper {
            padding: 10px;

            &__name {
              display: block;
              font-weight: 700;
              color: #000;
              text-transform: uppercase;

              &__designation {
                color: #05608c;
              }
            }

            &__company-name {
              color: #f27110;
              font-weight: 700;
              margin-bottom: 10px;
              display: block;
            }
          }
        }
      }
    }
  }
}

.panel-terms {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &__item {
    background: #ff5e00;
    color: #fff;
    border-radius: 15px;
    padding: 10px 10px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    width: 280px;
    height: 60px;
    font-size: 18x;
    box-shadow: 4px 7px 5px -5px rgba(0, 0, 0, 0.75);
    margin-bottom: 10px;

    &:hover {
      color: #fff;
      background: #067090;
    }
  }
}

/* Ends  HR-Meet*/

/* Start TPO Meet */

.tpo-event-details {
  display: flex;
  flex-direction: column;

  q {
    font-style: italic;
    font-weight: 700;
  }

  .tpo-event-details__content,
  .tpo-event-details__testimonials {
    width: 100%;
  }

  &__content {
    flex: 1 1 auto;
    margin-bottom: 25px;
    text-align: center;

    &__title {
      font-size: 24px;
      font-weight: bold;
      color: #000;
      line-height: 1;
      text-transform: uppercase;
      padding-top: 25px;
      margin-bottom: 40px;
    }

    &__details {
      line-height: 1.8;
      color: #000;

      &__highlight {
        color: #000000;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }

  &__testimonials {
    display: flex;
    flex-direction: column;

    &__title {
      font-size: 24px;
      font-weight: bold;
      color: #000;
      text-align: center;
      text-transform: uppercase;
      padding: 10px 0 0px;
    }

    &__item {
      background-color: #e8e8e8;
      flex: 1 1 0;
      padding: 15px;
      margin: 0 0 15px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__profile-pic {
        border-radius: 50%;
        width: 150px;
        height: 150px;
      }

      &__profile-name {
        font-weight: 700;
        color: #000;
        font-size: 16px;
        margin: 0;
      }

      &__college-name {
        height: 40px;
        font-size: 13px;
        font-weight: 600;
      }

      &__content {
        text-align: center;
        font-size: 15px;
      }
    }
  }
}

.speaker-discussion-points {
  flex-direction: column;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &__title {
    font-size: 30px;
    color: #06295E;
    text-align: center;
    font-weight: 600;
    padding: 50px 0 0px;
    width: 100%;
  }

  &__list {
    font-size: 16px;
    color: #000;
    line-height: 28px;
    list-style-type: none;

    &__item {
      margin-bottom: 15px;

      &:before {
        content: "";
        display: inline-block;
        background: url("/images/arrow-right.png") no-repeat center/contain;
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }
  }
}

/* End TPO Meet */

.event-speakers {
  &__title {
    text-align: center;
    text-transform: uppercase;
  }

  &__speakers {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      flex-direction: column;
      transition: all 0.5s ease 0s;
      text-align: center;
      margin-bottom: 15px;

      &:hover {
        box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
      }

      &__img {
        height: 100%;
        max-width: 185px;
        max-height: 185px;
        flex: 0 0 auto;
        margin: 0 auto;
      }

      &__details {
        margin: 0;
        padding: 15px;
        list-style: none;

        &__item {
          margin-top: 15px;

          &:first-child {
            margin-top: 0;
          }

          &__label {
            display: block;
          }

          &__value {
            display: block;
            color: #000;
            font-weight: bold;
          }

          &__value_speech {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

//Start CEO Meet

.event-associated-with {
  text-align: center;
  padding: 10px;
  background-color: #efe;
  margin-bottom: 20px;

  span {
    display: inline-block;
    color: #000;
    margin: 0 15px 5px 0;
  }

  img {
    max-height: 50px;
  }
}

.event-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__left {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    flex: 0 0 auto;
    order: 2;

    span {
      display: flex;
      color: #fff;
      align-items: center;
      justify-content: center;
      padding: 5px;
      height: 210px;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      font-size: 13px;

      &:nth-child(1) {
        background-color: #e6296a;
      }

      &:nth-child(2) {
        background-color: #3c5db1;
      }

      &:nth-child(3) {
        background-color: #383c45;
      }

      &:nth-child(4) {
        background-color: #2aa1d8;
      }
    }
  }

  &__right {
    flex: 1 1 auto;
    margin-bottom: 25px;
    text-align: center;

    &__title {
      font-size: 24px;
      font-weight: bold;
      color: #000;
      line-height: 1;
      text-transform: uppercase;
      text-decoration: underline;
    }

    &__details {
      line-height: 1.8;
      color: #000;

      em {
        font-size: 14px;
        font-weight: bold;
      }

      &__highlight {
        color: #000000;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
}

//End CEO Meet

.content-wrapper {
  padding: 30px 0;
}

// Start of Business IT
.business-it-moment {
  align-items: center;
  margin-bottom: 50px;
  text-align: center;
  background: #f2f2f2;
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &__main {
    &__item {
      +.business-it-moment__main__item {
        margin: 25px 0;
        display: block;
      }

      span {
        font-size: 12px;
        text-align: left;
        vertical-align: middle;
      }
    }
  }

  .btn {
    display: block;
    width: 100%;
  }
}

.date-icon {
  background: url("/images/calender.png") no-repeat 0 center / contain;
  padding: 15px 0 15px 80px;
  font-size: 22px;
  font-weight: bold;
  display: inline-block;
}

.time-icon {
  background: url("/images/business_clock.png") no-repeat 0 center / contain;
  padding: 15px 0 15px 80px;
  font-size: 22px;
  font-weight: bold;
}

.btn--webinar {
  padding: 15px 25px;
  font-weight: bold;
  font-size: 20px;

  &:hover,
  &:focus {
    color: #fff;
  }
}

.business-it-details {
  &__about {
    display: flex;
    flex-direction: column;

    &__main {
      &__heading {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      &__sub-heading {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }

    &__key-points {
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
      border: 1px solid #8ec2f0;
      border-radius: 8px;
      background: #f0f8ff;
      padding: 15px;

      &__heading {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      &__list {
        list-style: disc;
        margin: 5px 0 15px 15px;
      }
    }
  }

  &__speakers {
    margin: 40px 0 40px;

    &__heading {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    &__main {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &__item {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;

        &__content {
          flex: 1 1 auto;

          &__img {
            border-radius: 50%;
            width: 140px;
            margin-bottom: 5px;
          }

          &__name {
            font-weight: bold;
            font-size: 16px;
          }

          &__name,
          &__designation,
          &__company {
            display: block;
          }

          &__designation,
          &__company {
            color: #999;
          }

          &__speech {
            font-size: 14px;
            margin: 30px auto 0;
            line-height: 1.8;
            color: #666;
            display: block;
            width: 90%;
            position: relative;
            padding: 32px 0 17px;

            &:before {
              content: url(/images/quotes_start.png);
              position: absolute;
              left: 0;
              top: 0;
            }

            &:after {
              content: url(/images/quotes_end.png);
              position: absolute;
              right: 0;
              bottom: 0;
            }
          }
        }

        &__more {
          flex: 0 0 auto;
          display: block;
          background: #009cf6;
          color: #fff;
          padding: 10px 0;
          margin: 15px -10px -10px -10px;
          border-radius: 0 0 8px 8px;

          &:hover,
          &:active,
          &:focus {
            color: #fff;
          }
        }
      }
    }
  }

  &__alert {
    font-size: 18px;
    text-align: center;
  }
}

.business-speaker-details {
  display: flex;
  flex-direction: column;

  &__img {
    height: 140px;
    flex: 0 0 auto;
    border-radius: 50%;
    max-width: 140px;
    margin: 0 auto 15px;
  }

  &__content {
    flex: 1 1 auto;

    &__name {
      font-weight: bold;
      margin: 0;
      text-align: center;
    }

    &__designation {
      color: #999999;
      display: block;
      text-align: center;
    }

    &__bio,
    &__speech {
      margin-top: 20px;
    }
  }
}

.close {
  float: right;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  border: none;
  background: transparent;
}

/* Events*/

/* Privacy */
.privacy-policy {
  padding-top: 50px;

  &__section {
    margin-bottom: 50px;

    &__heading {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #143a7b;
      font-family: 'Montserrat', sans-serif;
    }

    &__paragraph {
      font-size: 16px;
      line-height: 2;
      margin-bottom: 0;

      +.privacy-policy__section__paragraph {
        margin-top: 25px;
      }
    }
  }
}

/* Appfutura Badge */
.appfutura-badge,
.iso-logo,
.goodfirms-logo,
.clutch_badges {
  margin-left: 10px;

  &__img {
    max-height: 120px;
    width: auto;
  }
}

/* Case Studies */
.case-study {
  font-size: 16px;

  &__section {
    margin-bottom: 30px;

    &__indicators {
      display: flex;
      align-items: center;
      margin-left: -65px;

      &__shape {
        display: inline-block;
        background: #fff;
        width: 30px;
        height: 30px;
        border: 1px solid #707070;
        border-radius: 50%;
        position: relative;
        margin-right: 35px;

        &::after {
          content: "";
          width: 25px;
          height: 1px;
          background: #ccc;
          display: inline-block;
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          left: 100%;
        }
      }

      &__number {
        font-size: 20px;
        color: #ccc;
      }
    }

    &__heading {
      font-family: 'Montserrat', sans-serif;
      font-size: 28px;
      color: #14387B;
      text-transform: uppercase;
      line-height: 1;

      &__light {
        font-weight: 300;
      }
    }

    &__overview {
      display: flex;
      flex-direction: column;

      &__main {
        line-height: 1.5;
        margin-bottom: 0;
        margin-bottom: 15px;
      }

      &__secondary {
        &__info {
          &:first-child {
            margin-bottom: 20px;
          }

          &__heading {
            color: #14387B;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 0;
          }

          &__item {
            background: #fff;
            border: 1px solid #333;
            padding: 5px 15px;
            margin: 0 10px 10px 0;
            font-size: 14px;
            display: inline-block;
          }
        }
      }
    }

    &__challenge {
      margin-top: 35px;

      &__sg-end-text {
        font-style: italic;
        font-weight: 500;
      }

      &__item {
        padding: 15px 0 0;
        position: relative;

        &__main {
          display: flex;
          flex-direction: column;

          img {
            max-width: 138px;
          }

          &__content {
            &__heading {
              text-transform: uppercase;
              color: #14387B;
              font-size: 20px;
              margin-bottom: 0;
            }

            &__details {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &__solution {
      &--desktop {
        display: none;
      }

      &--mobile {
        img {
          width: 100%;
        }
      }

      &__item+.case-study__section__solution__item {
        margin-top: 50px;
      }

      &__head {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;

        &__left,
        &__right {
          width: 49%;
          text-align: center;
        }
      }

      &__row {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        position: relative;
        flex-wrap: wrap;
        border: 2px dashed #ccc;

        &__left,
        &__right {
          width: 100%;
          padding: 10px;

          &__lists {
            list-style-type: disc;
            padding-left: 25px;
          }
        }

        &__left--sg img {
          width: 100%;
        }

        &__left--img {
          text-align: center;
        }

        &__left--full-width {
          width: 100% !important;
          text-align: center;
        }
      }
    }

    &__results {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 170px 0 -210px;
      padding-bottom: 50px;

      &__item {
        width: 100%;
        text-align: center;
        background: #efefef;
        border: 1px solid #ccc;
        padding: 0 15px 15px;
        border-radius: 4px;
        margin-bottom: 160px;

        &__img {
          margin-top: -135px;
        }

        &__heading {
          font-weight: bold;
          font-size: 20px;
          margin-bottom: 8px;
        }

        &__content {
          margin-bottom: 0;
        }
      }
    }

    &__technologies {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &__item {
        text-align: center;
        width: 48%;
        margin-bottom: 25px;

        &__logo {
          background: #fff;
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
          width: 100%;
          height: 125px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
        }

        &__text {
          font-size: 16px;
        }
      }
    }
  }
}